/* Mixins */
.inspection-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.inspection-component .jtl-form-component {
  width: 100%;
}
.inspection-component .inspection-check-list-title {
  border: 1px solid #d9d9d9;
  padding: 3px;
  font-weight: bold;
  background-color: #F5F5F5;
}
.inspection-component .table-select-inspec {
  width: 90px !important;
  min-width: 90px !important;
}
.inspection-component .inspection-list-table-row {
  height: 40px !important;
  line-height: 40px !important;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.inspection-component .inspection-list-table-row .ant-col {
  border: 0.5px solid #cdcdcd;
  border-width: 0 0 0.5px 0.5px;
  line-height: 38px;
  height: 40px;
}
.inspection-component .inspection-list-table-row .ant-col input {
  width: 96%;
  padding-left: 2%;
  border-width: 0px !important;
  height: 39px;
}
.inspection-component .inspection-list-table-row .ant-col:last-child {
  border-right: 0.5px solid #cdcdcd;
}
.inspection-component .a-label {
  color: #2393cd;
}